.staking-body{
    /* display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center; */
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.staking-body h1{
    font-family: 'Rebellion';
    color: #ffc93d;
}