.NFT-page-body{
    width: 100%;
    height: 100%;
    display: flex;
    min-height: 80vh;
    justify-content: space-evenly;
    align-items: center;
}

.NFT-body{
    border: white 1px solid;
    /* height: 60vh; */
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    box-sizing: border-box;
    padding-top: 10px;
}

.NFT-image{
    border: solid white 1px;
    aspect-ratio : 1 / 1;
    width: 80%;
}

.NFT-image img{
    width: 100%;
    height: 100%;
}

.purchase-section{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.price-text{
    color: white;
    font-size: small;
    font-weight: bold;
    padding-top: 10px;
}

.purchase-section-buttons{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 10px;
}

.purchase-section-buttons button{
    font-size: large;
    width: 25px;
    height: 25px;
}

.incrementer{
    width: 30px;
    text-align: center;
    color: black;
    background-color: white;
    margin-left: 20px;
    margin-right: 20px;
    font-weight: bold;
}

.buy-button{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: medium;
    font-weight: bold;
}